import styles from './Client.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import { blueGrey } from '@mui/material/colors';






const top100Films = () => [
  { label: 'Малая Бронная 15', year: 1994 },
  { label: 'THE PATRICKS', year: 1972 },
  { label: 'Загородная недвижимость', year: 1972 },
  { label: 'Никитский 6', year: 1972 },
]




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const TextMaskCustomClient = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7(#00) 000-00-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustomClient.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};




const Broker = () => {
    const [name, setName] = React.useState('1')
    const [clientName, setClientName] = React.useState('')
    const [clientAgentstvo, setClientAgentstvo] = React.useState('')
    const [address, setAddress] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [time, setTime] = React.useState('')
    const [values, setValues] = React.useState({
      textmask: '000-00',
      numberformat: '1320',
      
    });
    const [valuesClient, setValuesClient] = React.useState({
      textmask: '+7(900) 000-00-00',
      numberformat: '1320',
      
    });
    const [checked, setChecked] = React.useState(false);



    let tg = window.Telegram.WebApp;

  tg.MainButton.text = "Отправить"; //изменяем текст кнопки 
  tg.MainButton.setText("Отправить"); //изменяем текст кнопки иначе
  tg.MainButton.textColor = "#000000"; //изменяем цвет текста кнопки
  tg.MainButton.color = "#CED8DD"; //изменяем цвет бэкграунда кнопки
  tg.MainButton.setParams({"color": "#CED8DD"}); 
     


  const handleChangeChekced = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true && name != ''){
      tg.MainButton.show()
    }
    else if(event.target.checked === false ){
      tg.MainButton.hide()
    }
  };

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
      if(event.key == 'Enter' || event.keyCode == 13){
        event.target.blur();
    }
    };

    const onKeyDown = (e) => {
      if(e.key == 'Enter' || e.keyCode == 13){
        e.target.blur();
    }}

    const handleChangeClient = (event) => {
      setValuesClient({
        ...valuesClient,
        [event.target.name]: event.target.value,
      });
      if(event.key == 'Enter' || event.keyCode == 13){
        event.target.blur();
    }
    };
    
    const onChangeName = (e) => {
       setName(e.target.value)
       if(e.key == 'Enter' || e.keyCode == 13){
        e.target.blur();
    }
       if (checked == true){
        tg.MainButton.show()
      }
      else if(e.target.value === '' ){
        tg.MainButton.hide()
      }
      
    }

    const onChangeClientName = (e) => {
      setClientName(e.target.value)
      if(e.key == 'Enter' || e.keyCode == 13){
        e.target.blur();
    }
      if (checked == true && name != ''){
       tg.MainButton.show()
      }
      else if(e.target.value === '' ){
        tg.MainButton.hide()
      }
      
   }


   const onChangeClientAgentstvo = (e) => {
    setClientAgentstvo(e.target.value)
    if(e.key == 'Enter' || e.keyCode == 13){
      e.target.blur();
  }
    if (checked == true && name != ''){
     tg.MainButton.show()
    }
    else if(e.target.value === '' ){
      tg.MainButton.hide()
    }
   }

    const onChangeAddress = (e) => {
          setAddress(e.target.outerText)
         
          if (name != '' && checked == true){
            tg.MainButton.show()
          }
          else if(e.target.outerText === '' ){
            tg.MainButton.hide()
          }
          if( e.key == 'Enter'){
            e.target.blur();
          }
     
    }

    const onChangeAddress2 = (e) => {
          setAddress(e.target.value)
          
          if (name != '' && checked == true){
            tg.MainButton.show()
          }
    }

    const sendDataToTelegram = () => {
      if (checked==true){tg.sendData(`{"agentstvobroker": "${clientAgentstvo}", "namebroker": "${clientName}", "phonebroker":  "${valuesClient.textmask}", "addressclientbroker":  "${address}", "timebroker": "${time}"}`);}
      
    }
  

    React.useEffect(() => {
      tg.onEvent('mainButtonClicked', sendDataToTelegram)
      return () => {
        tg.offEvent('mainButtonClicked', sendDataToTelegram)
      }
    }, [sendDataToTelegram])


    React.useEffect(() => {
      tg.MainButton.hide()
    },[])



const today = new Date();
today.setHours(today.getHours() + 3);

const onchangetime = (e) =>{
    setTime(e.target.value)
}

  return (
    <Box
    component="form"
    sx={{p:3}}
    noValidate
    autoComplete="off"
  >
    <h3 sx={{pb:3}}>Записаться на брокер тур</h3>
    
    

    <Typography sx={{mb:1}} style={{color:"rgb(177, 177, 177)", fontSize: '12px'}}>Ваши данные</Typography>
    <TextField fullWidth id="outlined-basic" sx={{pb:3}} label="Агентство недвижимости" variant="standard" onKeyDown={onKeyDown} onChange={onChangeClientAgentstvo}/>
    <TextField fullWidth id="outlined-basic" sx={{pb:3}} label="Ваше имя и фамилия" variant="standard" onKeyDown={onKeyDown} onChange={onChangeClientName}/>

    <FormControl fullWidth   variant="standard">
        <InputLabel htmlFor="formatted-text-mask-input">Телефон</InputLabel>
        <Input
          onKeyDown={onKeyDown}
          value={valuesClient.textmask}
          onChange={handleChangeClient}
          name="textmask"
          id="formatted-text-mask-input "
          inputComponent={TextMaskCustomClient}
        />
      </FormControl>
 

    <Autocomplete
    sx={{mt:3,}}
      freeSolo
      
      id="combo-box-demo"
      options={top100Films()}
      onChange={onChangeAddress}
      renderInput={(params) => <TextField fullWidth onChange={onChangeAddress2} {...params} label="Адрес объекта" variant="standard" />}
    />

    
<TextField
        fullWidth
        position="start"
        variant="standard"
        id="datetime-local"
        label="Дата и время"
        type="datetime-local"
        onChange={onchangetime}
        defaultValue={today.toISOString().slice(0, 16)}
       
        sx={{  mt:3, pb:3 }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <FormGroup sx={{pt:3, pb:50}}>
      <FormControlLabel control={<Checkbox  checked={checked}
      onChange={handleChangeChekced}/>} label={
        <span>
            <a href={'/persdata'} style={{ color: blueGrey[900]}}> 
            Даю согласие на обработку персональных данных.
            </a>
            &nbsp;
            <a href={'/confidec'} style={{ color: blueGrey[900]}}> 
            Ознакомлен с политикой конфиденциальности.
            </a>
        </span>
    } />
    </FormGroup>

  </Box>
  );
};
 
export default Broker;